<template>
  <DataBlockBase>
    <div class="alert alert-danger">This block type has not been implemented!</div>
  </DataBlockBase>
</template>

<script>
import DataBlockBase from "@/components/datablocks/DataBlockBase.vue";

export default {
  components: {
    DataBlockBase,
  },
};
</script>
